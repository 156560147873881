export const LockPathwayIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0'
    y='0'
    enableBackground='new 0 0 371.11 370.46'
    viewBox='0 0 371.11 370.46'>
    <defs>
      <path
        id='LockPathway-1'
        d='M469.92 564.22H969.9200000000001V845.33H469.92z'></path>
    </defs>
    <clipPath id='LockPathway-2'>
      <use overflow='visible' xlinkHref='#LockPathway-1'></use>
    </clipPath>
    <path
      fill='#F1F1F2'
      d='M874.9 809.27c.02-.01.03-.01.05-.02 9.96-3.77 19.71-7.85 29.33-12.12 72.11-25.59 147.67-22.66 214.07 3.22 6.29 2.45 12.48 5.14 18.59 8 79.55 31.36 170.57 34.39 256.86 1.75 184.21-69.68 277.06-275.49 207.38-459.71-33.89-89.6-100.04-157.5-180.2-195.68a359.268 359.268 0 00-49.37-19.2c-67.68-33.16-123.41-91.06-152.18-167.12-28.44-75.2-25.41-154.56 2.5-223.66 3.41-8.43 7.14-16.73 11.28-24.83 39.7-95.25 44.62-205.22 5.23-309.36C1156.55-805.9 914.71-915 698.25-833.13 522.34-766.59 417.34-594.4 428.11-416.05c.59 9.88 1.67 19.77 2.99 29.67 4.66 90.98-31.7 179.47-97.98 240.96-7.83 6.21-15.43 12.63-22.87 19.22C142.57 22.3 74.95 264.29 158.9 486.24c108.53 286.91 429.09 431.53 716 323.03z'
      clipPath='url(#LockPathway-2)'></path>
    <path
      fill='none'
      d='M235.44 167.58L98.39 132.05a6.373 6.373 0 00-7.76 4.56L55.5 272.14a6.373 6.373 0 004.56 7.76l137.04 35.53c3.4.88 6.87-1.16 7.76-4.56L240 175.34c.88-3.4-1.16-6.87-4.56-7.76zm-41.3 133.92L69.42 269.17l31.94-123.2 124.72 32.34-31.94 123.19z'></path>
    <path
      fill='none'
      d='M137.78 236.84l-4.97 19.18a6.373 6.373 0 004.56 7.76c3.4.88 6.87-1.16 7.76-4.56l4.97-19.18c13.06.39 25.15-8.27 28.57-21.43 3.96-15.29-5.25-30.95-20.53-34.91-15.29-3.96-30.95 5.25-34.91 20.53-3.42 13.16 2.95 26.6 14.55 32.61zm17.16-40.83c8.51 2.21 13.61 10.89 11.41 19.39-2.21 8.51-10.89 13.61-19.39 11.41-8.51-2.21-13.61-10.89-11.41-19.39 2.2-8.51 10.88-13.61 19.39-11.41z'></path>
    <linearGradient
      id='LockPathway-3'
      x1='-419.316'
      x2='-304.767'
      y1='-537.41'
      y2='-537.41'
      gradientTransform='rotate(14.53 -2476.23 1920.057)'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#LockPathway-3)'
      d='M154.16 72.25c4.41-17.01 21.78-27.23 38.79-22.82 17.01 4.41 27.23 21.78 22.82 38.79l-10.05 38.77 24.64 6.39 10.05-38.77c7.94-30.62-10.45-61.88-41.07-69.82-30.62-7.94-61.88 10.45-69.82 41.07l-10.05 38.77 24.64 6.39 10.05-38.77z'></path>
    <path
      fill='#00AECE'
      d='M146.95 226.81c8.51 2.21 17.19-2.9 19.39-11.41 2.21-8.51-2.9-17.19-11.41-19.39-8.51-2.21-17.19 2.9-19.39 11.41-2.2 8.5 2.91 17.19 11.41 19.39z'></path>
    <path
      fill='#00AECE'
      d='M69.42 269.17l124.72 32.34 31.94-123.2-124.72-32.34-31.94 123.2zm88.71-85.48c15.29 3.96 24.5 19.62 20.53 34.91-3.41 13.17-15.51 21.82-28.57 21.43l-4.97 19.18c-.88 3.4-4.36 5.44-7.76 4.56-3.4-.88-5.44-4.36-4.56-7.76l4.97-19.18c-11.6-6.01-17.97-19.44-14.55-32.61 3.97-15.28 19.63-24.5 34.91-20.53z'></path>
    <linearGradient
      id='LockPathway-4'
      x1='-458.289'
      x2='-265.807'
      y1='-380.55'
      y2='-380.55'
      gradientTransform='rotate(14.53 -2476.23 1920.057)'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#LockPathway-4)'
      d='M251.29 207.85l8.8-33.96c2.6-10.18-3.52-20.56-13.71-23.2L97.02 111.97c-10.21-2.65-20.63 3.48-23.27 13.69L35.42 273.51c-2.65 10.21 3.48 20.63 13.69 23.27l149.36 38.73c10.18 2.64 20.57-3.45 23.25-13.61l22.38-86.32 7.19-27.73zm-46.43 103.02c-.88 3.4-4.36 5.44-7.76 4.56L60.06 279.9c-3.4-.88-5.44-4.36-4.56-7.76l35.14-135.53c.88-3.4 4.36-5.44 7.76-4.56l137.04 35.53c3.4.88 5.44 4.36 4.56 7.76l-35.14 135.53z'></path>
    <path
      fill='#FD67FF'
      d='M230.53 287.94l22.09 5.73 10.23 17.4c-4.15 2.93-7.34 7.29-8.71 12.58-3.08 11.89 4.08 24.07 15.97 27.15 11.89 3.08 24.07-4.08 27.15-15.97 3.69-14.22-7.25-28.19-22.05-27.85l-12.96-22.03a6.361 6.361 0 00-3.89-2.93l-24.64-6.39'></path>
    <path
      fill='#8A2FF1'
      d='M231.98 324.55c.01-.03.01-.05.02-.07M240.91 247.9l33.55 8.7c.57 9.37 7.08 17.73 16.64 20.21 11.89 3.08 24.07-4.08 27.15-15.97 3.08-11.89-4.08-24.07-15.97-27.15-9.93-2.57-20.06 2-24.93 10.51l-33.26-8.62'></path>
    <path
      fill='#00AECE'
      d='M275.93 214.24c1.63.42 3.37.18 4.82-.67l22.03-12.96c12.78 7.49 29.12.58 32.81-13.63 3.08-11.89-4.08-24.07-15.97-27.15-11.89-3.08-24.07 4.08-27.15 15.97-1.37 5.3-.71 10.65 1.5 15.23l-17.4 10.23-22.09-5.73 5.59-21.56c.01-.03.01-.05.02-.08l-8.8 33.96 24.64 6.39z'></path>
  </svg>
)
