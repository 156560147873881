export const ChartsTimeIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='17 17 67 67'>
    <linearGradient
      x1='20.748'
      x2='99.326'
      y1='87.564'
      y2='34.156'
      gradientUnits='userSpaceOnUse'
      id='ChartsTimeIcon-1'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      d='M71.06 33.65H79.48V67.35H71.06z'
      fill='url(#ChartsTimeIcon-1)'></path>
    <linearGradient
      id='ChartsTimeIcon-2'
      x1='16.847'
      x2='95.425'
      y1='81.825'
      y2='28.417'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#ChartsTimeIcon-2)'
      d='M54.21 56.39c3.53 1.06 6.53 3.4 8.42 6.56V44.19h-8.42v12.2z'></path>
    <linearGradient
      id='ChartsTimeIcon-3'
      x1='20.724'
      x2='99.302'
      y1='87.53'
      y2='34.121'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#ChartsTimeIcon-3)'
      d='M48.95 70.91l-3.63 1.81.94 1.88 4.21-2.11a.99.99 0 00.42-.39c.1-.17.16-.36.16-.55v-8.42h-2.11v7.78z'></path>
    <linearGradient
      id='ChartsTimeIcon-4'
      x1='22.064'
      x2='100.642'
      y1='89.501'
      y2='36.093'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#ChartsTimeIcon-4)'
      d='M58.6 61.28a12.616 12.616 0 00-8.97-3.38 12.6 12.6 0 00-8.76 3.89c-2.3 2.4-3.56 5.6-3.51 8.92.05 3.32 1.4 6.49 3.77 8.81 2.36 2.33 5.55 3.63 8.87 3.63 1.78 0 3.54-.38 5.16-1.1 1.62-.73 3.07-1.79 4.26-3.11l-1.57-1.4a10.503 10.503 0 01-6.24 3.4c-2.41.38-4.88-.09-6.98-1.33-2.1-1.24-3.71-3.17-4.55-5.47-.84-2.29-.85-4.81-.04-7.11.81-2.3 2.4-4.25 4.48-5.52 2.09-1.26 4.55-1.77 6.97-1.42s4.64 1.53 6.28 3.33a10.58 10.58 0 012.74 6.56h-3.13l4.21 5.62 4.21-5.62h-3.19c-.14-3.31-1.58-6.44-4.01-8.7z'></path>
    <linearGradient
      id='ChartsTimeIcon-5'
      x1='30.05'
      x2='108.627'
      y1='101.25'
      y2='47.841'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#ChartsTimeIcon-5)'
      d='M67.9 69.46H82.64V71.57H67.9z'></path>
    <linearGradient
      id='ChartsTimeIcon-6'
      x1='14.576'
      x2='93.154'
      y1='78.484'
      y2='25.076'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#ChartsTimeIcon-6)'
      d='M17.36 69.46H35.26V71.57H17.36z'></path>
    <linearGradient
      id='ChartsTimeIcon-7'
      x1='7.223'
      x2='85.801'
      y1='67.666'
      y2='14.258'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#ChartsTimeIcon-7)'
      d='M20.51 44.19H28.93V67.36H20.51z'></path>
    <linearGradient
      id='ChartsTimeIcon-8'
      x1='2.041'
      x2='80.618'
      y1='60.041'
      y2='6.633'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#ChartsTimeIcon-8)'
      d='M21.11 38.53c.76.51 1.65.78 2.55.78.4 0 .79-.05 1.18-.16a4.573 4.573 0 002.93-2.4c.54-1.1.62-2.36.23-3.52l9.9-7.43c.83.6 1.85.89 2.88.84 1.03-.06 2-.46 2.77-1.14l10.41 6.13c-.07.32-.11.64-.12.97a4.582 4.582 0 006.87 3.96A4.582 4.582 0 0063 32.6c0-.37-.05-.74-.15-1.1l9.49-5.92a4.55 4.55 0 003.92.96 4.576 4.576 0 003.27-2.78c.56-1.41.39-3.01-.47-4.27a4.627 4.627 0 00-3.8-2c-1.22 0-2.37.48-3.24 1.34a4.57 4.57 0 00-1.34 3.24c0 .37.05.74.15 1.1l-9.49 5.92c-.84-.71-1.9-1.07-3-1.07-1.1.01-2.15.42-2.97 1.15l-10.41-6.12c.07-.32.11-.64.12-.97 0-1.38-.61-2.66-1.67-3.54a4.547 4.547 0 00-3.79-.95 4.594 4.594 0 00-3.15 2.32 4.541 4.541 0 00-.3 3.64l-9.9 7.43c-.76-.54-1.68-.84-2.61-.84h-.01c-1.32 0-2.57.57-3.45 1.56a4.59 4.59 0 00-1.1 3.62c.19 1.3.92 2.47 2.01 3.21z'></path>
    <linearGradient
      id='ChartsTimeIcon-9'
      x1='7.744'
      x2='86.322'
      y1='68.432'
      y2='15.024'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#ChartsTimeIcon-9)'
      d='M45.79 56.39V33.65h-8.42v29.29c1.88-3.15 4.89-5.49 8.42-6.55z'></path>
  </svg>
)
