export const AdaptingShapesIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0'
    y='0'
    viewBox='13 25 74 55'>
    <linearGradient
      x1='17.523'
      x2='85.665'
      y1='79.443'
      y2='26.648'
      gradientUnits='userSpaceOnUse'
      id='AdaptingShapesIcon-1'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      d='M86.6 69.57V47.31c0-5.53-4.5-10.04-10.04-10.04h-1.68c-5.53 0-10.04 4.5-10.04 10.04v8.66h-6.51l-7.47-7.47V27.43c0-.9-.73-1.63-1.63-1.63H24.65c-.9 0-1.63.73-1.63 1.63v19.05c-5.43.8-9.62 5.47-9.62 11.12 0 6.2 5.05 11.25 11.25 11.25 5.65 0 10.32-4.19 11.12-9.62h4.36l7.95 7.95c.64.64 1.67.64 2.31 0l7.95-7.95h6.51v10.34c0 5.53 4.5 10.04 10.04 10.04h1.68C82.1 79.6 86.6 75.1 86.6 69.57zm-39-40.51v19.43l-7.47 7.47h-4.36c-.72-4.89-4.59-8.77-9.48-9.48V29.06H47.6z'
      fill='url(#AdaptingShapesIcon-1)'></path>
  </svg>
)
