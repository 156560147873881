import { AnimateIn } from '@/web/components/AnimateIn'
import { LockPathwayIcon } from './icons/custom/LockPathwayIcon'

export const LandingPrivacy = () => (
  <AnimateIn className='mx-auto grid max-w-3xl grid-cols-1 text-left md:[grid-template-columns:2fr_1fr] lg:max-w-4xl xl:max-w-5xl xl:px-0 2xl:max-w-7xl'>
    <div className='flex flex-1 flex-col gap-6 px-8 py-8 md:py-0 xl:px-0'>
      <h1>
        Our commitment to <span className='text-gradient'>privacy</span>
      </h1>
      <h4 className='text-muted-foreground'>
        Pulse is actively pursuing SOC 2 (Type II) compliance, and this is just
        the beginning of our commitment to data security. We are dedicated to
        creating ethical, privacy-focused AI solutions. Our approach includes
        limited data collection, bias identification, unique anonymization
        technologies, and robust encryption for stored data (AES-256) and data
        in motion (HTTPS, RSA-2048).
      </h4>
    </div>
    <div className='m-auto w-80 [grid-row-start:1] md:[grid-column-start:2]'>
      <LockPathwayIcon />
    </div>
  </AnimateIn>
)
