import { AnimateIn } from '@/web/components/AnimateIn'
import { FeatureCards, type Feature } from '@/web/components/FeatureCards'
import { AdaptingShapesIcon } from './icons/custom/AdaptingShapesIcon'
import { BinaryTreeIcon } from './icons/custom/BinaryTreeIcon'
import { ChartsTimeIcon } from './icons/custom/ChartsTimeIcon'
import { GearSourcesIcon } from './icons/custom/GearSourcesIcon'
import { LetterWriteIcon } from './icons/custom/LetterWriteIcon'
import { PlugIcon } from './icons/custom/PlugIcon'

export const COMPANY_FEATURES: Feature[] = [
  {
    icon: GearSourcesIcon,
    title: 'Creates Your Tasks',
    description:
      'Before you read your messages, tackle your inbox, or check your projects, Pulse has already done it for you. You’ll see all of your tasks in one place so you know what needs to get done.',
  },
  {
    icon: BinaryTreeIcon,
    title: 'Prioritizes Your Tasks',
    description:
      'Your tasks are prioritized according to their importance and urgency. We use industry standards like the Eisenhauer Matrix and RICE Scoring Model to make sure you’re making the most of your time.',
  },
  {
    icon: LetterWriteIcon,
    title: 'Responds In A Click',
    description:
      'Pulse makes reacting to your notifications as simple as possible. Reply to messages and emails, schedule events, and update your project issues. All without writing a single word.',
  },
  {
    icon: ChartsTimeIcon,
    title: 'Keeps You Up-To-Date',
    description:
      'Is your task blocking someone’s work? Did an urgent issue just surface? Tackle your day knowing that your task list is constantly being updated.',
  },
  {
    icon: AdaptingShapesIcon,
    title: 'Adapts To Your Needs',
    description:
      'Pulse learns from your activity and feedback, continuously fine-tuning your task generation, prioritization, and automation.',
  },
  {
    icon: PlugIcon,
    title: 'Connects To Your Tools',
    description:
      'Pulse integrates deeply with your everyday productivity tools. You can filter exactly what’s monitored, down to the conversation, project, inbox, or calendar.',
  },
]

export const LandingFeatures = () => (
  <AnimateIn className='wavy-border-top wavy-border-bottom bg-base-300 px-8 py-8 xl:px-20 xl:py-20'>
    <h1 className='text-center'>
      Key <span className='text-gradient'>features</span>
    </h1>
    <FeatureCards features={COMPANY_FEATURES} />
  </AnimateIn>
)
